<template>
      <div style="max-width: 450px;" class="flex-1 flex flex-col p-5">
        <div v-if="props.logo" class="p-6 flex justify-center mb-12">
      <a href="https://tickable.nl/">
        <img :src="isDark ? whiteLogo : darkLogo" style="width: 150px;" />
      </a>
    </div>
    <div class="w-full">
      <div class="flex gap-2 items-center mb-4 justify-content-cente">
        <div class="flex items-center justify-center bg-red-100 rounded-border"
          style="width:2.5rem;height:2.5rem">
          <i class="pi pi-exclamation-triangle text-red-500 text-xl"></i>
        </div>
        <h1 class="text-surface-900 dark:text-surface-0 font-semibold text-xl">Er is een fout opgetreden</h1>
      </div>
      <p class="text-lg text-surface-600 dark:text-surface-200">
        Foutcode: {{ error?.statusCode }} - {{ error?.message ?? error?.error }}
      </p>
      <div class="pb-6">
        <p>We hebben deze fout meteen naar onze developers gestuurd.</br></br> Ervaar je dit probleem vaker? Hier zijn enkele stappen die je kunt proberen:</p>
        <ul class="flex flex-col gap-2 mt-4">
          <li>Gebruik een andere webbrowser, zoals Chrome, Firefox, of Edge.</li>
          <li>Wis je browsergegevens, waaronder cookies en cache.</li>
          <li>Controleer je internetverbinding en probeer het opnieuw.</li>
        </ul>
      </div>

      <NuxtLink to="/">
        <Button label="Probeer opnieuw" class="w-full" type="submit" rounded />
      </NuxtLink>
      <NuxtLink to="https://tickable.nl/contact/" target="_blank">
        <Button label="Neem contact op" text class="w-full mt-2" type="submit" rounded />
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDark } from '@vueuse/core'
import { NuxtError } from '#app';
import darkLogo from '~/assets/img/logo-dark.svg'
import whiteLogo from '~/assets/img/logo-white.svg'


const isDark = useDark();
const props = defineProps({
  error: Object as () => NuxtError & {error?: string},
  logo: Boolean
})
onMounted( ()=> {
  console.error(props.error?.error);
  //@ts-ignore
  console.error(props.error?.error?.['value']);
})


</script>

<style></style>