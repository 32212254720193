import { definePreset } from "@primevue/themes";
import Aura from "@primevue/themes/aura";
import chroma from "chroma-js";

const createColorShades = (baseColor: string) => {
  const scale = chroma.scale(["ffffff", baseColor]);
  return {
    50: scale(0.05).hex(),
    100: scale(0.1).hex(),
    200: scale(0.2).hex(),
    300: scale(0.3).hex(),
    400: scale(0.4).hex(),
    500: chroma(baseColor).hex(),
    600: chroma(baseColor).darken(0.5).hex(),
    700: chroma(baseColor).darken(1).hex(),
    800: chroma(baseColor).darken(1.5).hex(),
    900: chroma(baseColor).darken(2).hex(),
    950: chroma(baseColor).darken(2.5).hex(),
  };
};

export const MyPreset = definePreset(Aura, {
  semantic: {
    primary: createColorShades("4e8387"),
  },
  components: {
   
  },
});
//Your customizations, see the following sections for examples

export default {
  preset: MyPreset,
  options: {
    darkModeSelector: ".p-dark",
    cssLayer: false
  },
};
